<template>
  <AppLayout>
    <DesktopBanner> </DesktopBanner>
  </AppLayout>
  <HomeBackup />
  <div class="pageLeft" :style="isShowLeft? 'margin-left:0px;transition: margin-left 0.4s' : 'margin-left: -81.56rem;transition: margin-left 0.4s'">
    <div class="pageLeft-1">
      <div>
        <div class="pageLeft-2">
          <img style="width: 20px; height: 20px" src="./img/z-2.png" />
          <div class="pageLeft-3">业务咨询</div>
          <div class="pageLeft-4">（韩经理：13995648183）</div>
          <img style="width: 24px; height: 20px" src="./img/z-4.png" />
          <div class="pageLeft-5">商务邮箱</div>
          <div class="pageLeft-6">（zuoyi@iningmei.com）</div>
          <img style="width: 23px; height: 20px;cursor: pointer;" src="./img/z-3.png" @click="handleOpen" />
          <div class="pageLeft-8" @click="handleOpen">在线咨询</div>
          <img
              style="width: 6px; height: 12px; margin-top: 5px; margin-left: 8px;cursor: pointer;"
              src="./img/z-6.png"
              @click="handleOpen"
          />
          <img
              style="width: 20px; height: 20px; margin-left: 127px;cursor: pointer;"
              src="./img/z-5.png"
              @click="workSubmite"
          />
          <div class="pageLeft-9" @click="workSubmite">提交需求</div>
          <img
              style="width: 6px; height: 12px; margin-top: 5px; margin-left: 8px;cursor: pointer;"
              src="./img/z-6.png"
              @click="workSubmite"
          />
        </div>
        <div class="pageLeft-10">
          <div class="pageLeft-10-1">随时联系我们的咨询专家为您答疑解惑</div>
          <div class="pageLeft-10-2">我们的商务经理收到邮件会第一时间联系您</div>
          <div class="pageLeft-10-3" @click="handleOpen">在线咨询，技术专家将为您即时解答</div>
          <div class="pageLeft-10-4" @click="workSubmite">
            提交产品需求，我们将更精准的为您提供服务
          </div>
        </div>
      </div>
      <div class="pageLeft-jiangtou" @mouseover="rollIn" @click="closeLeft">
        <img
            v-if="!isShowLeft"
            style="width: 24px;height: 28px;"
            class="pageLeft-10-5"
            src="./img/z-0.png"
            alt=""
        />
        <img
            v-else
            style="width: 16px; height: 16px;"
            class="pageLeft-10-5"
            src="./img/z-1.png"
            alt=""
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import AppLayout from "@/components/AppLayout";
import HomeBackup from "../home/components/HomeBackup.vue";
import DesktopBanner from "./components/DesktopBanner.vue";
import { useRouter } from "vue-router";
import emitter from "@/utils/eventBus";
import { ref , onMounted } from "vue";
onMounted(() => {
  setTimeout(function(){
    isShowLeft.value = false;
  },2500)
	window.pageYOffset = 0 // 滚动条距离
});
const router = useRouter();
const workSubmite = () => {
  router.push({
    path: "/Customize",
    query: {
      item: "customizeDataList",
    },
  });
};
const isShowLeft = ref(true);
const isClose = ref(false); // 是否点击关闭按钮
// 点击关闭按钮
const closeLeft = () => {
  isClose.value = true;
  isShowLeft.value = false;
};
// 鼠标移入时触发
const rollIn = () => {
  if(!isClose.value){
    isShowLeft.value = true
  }
  setTimeout(()=>isClose.value = false,1000)
};
const handleOpen = () => {
  emitter.emit("onlineClient");
};
</script>

<style lang="less" scoped>
.pageLeft{
  width: 1440px;
  height: 140px;
  box-shadow: 0px 1px 30px 0px rgba(0,0,0,0.1);
  opacity: 0.95;
  border-radius: 0px 10px 10px 0px;
  bottom: 50px;
  position: fixed;
  z-index: 100;
  //margin-left: -1305px;
  //transition-delay: 99999s;
  /*&:hover {
    margin-left: 0px;
    transition: 0.5s margin-left;
    transition-delay: 0s;
  }
  &:hover .pageLeft-1 .pageLeft-jiangtou .pageLeft-10-5{
    opacity: 1;
    transition-delay: 0s;
    position: relative;
  }
  &:hover .pageLeft-1 .pageLeft-jiangtou .pageLeft-10-5-hover{
    opacity: 0;
    transition-delay: 0s;
    //position: absolute;
  }*/
  .pageLeft-1{
    width: 1440px;
    height: 140px;
    background: #FFFFFF;
    box-shadow: 0px 1px 30px 0px rgba(0,0,0,0.1);
    //opacity: 0.95;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    .pageLeft-2{
      display: flex;
      padding: 45px 0 0 78px;
      .pageLeft-3{
        width: 90px;
        height: 19px;
        line-height: 19px;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #111111;
        padding-left: 8px;
      }
      .pageLeft-4{
        width: 240px;
        height: 17px;
        line-height: 17px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ce1200;
      }
      .pageLeft-5{
        width: 90px;
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        padding-left: 8px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #111111;
      }
      .pageLeft-6{
        width: 229px;
        height: 17px;
        line-height: 17px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ce1200;
      }
      .pageLeft-8{
        width: 90px;
        height: 19px;
        line-height: 19px;
        font-size: 20px;
        padding-left: 8px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #111111;
        cursor: pointer;
      }
      .pageLeft-9{
        width: 90px;
        height: 20px;
        line-height: 18px;
        font-size: 20px;
        padding-left: 8px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #111111;
        cursor: pointer;
      }
    } 
    .pageLeft-10{
      padding: 15px 0 0 77px;
      display: flex;
      .pageLeft-10-1{
        width: 238px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
      .pageLeft-10-2{
        width: 267px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-left: 112px;
      }
      .pageLeft-10-3{
        width: 225px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-left: 74px;
        cursor: pointer;
      }
      .pageLeft-10-4{
        width: 281px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-left: 31px;
        cursor: pointer;
      }
    }
    .pageLeft-jiangtou{
      width: 100%;
      text-align: center;
      cursor: pointer;
      .pageLeft-10-5{
        width: 281px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-top: 40%;
        cursor: pointer;
      }
    }
  }
}
</style>
