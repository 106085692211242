<template>
  <div class="container-desktop" id="refreshtop">
    <div class="lunbo-point-tab">
      <el-carousel :interval="5000" ref="lokk" @change="check" height="38rem">
        <el-carousel-item v-for="item in banner" :key="item.id">
          <h3 v-if="item.bannerType == 1">
            <video
              id="media"
              ref="videoRef"
              :src="item.videoUrl"
              width="1920"
              :fill="true"
              height="600"
              autoplay="autoplay"
              preload="auto"
              controlslist="nodownload noplaybackrate "
              disablePictureInPicture="true"
              @click="clickPage(item)"
              @ended="handleended"
              :muted="true"
              :controls="false"
            ></video>
          </h3>
          <h3 :class="item.clickEvent == 0 ? 'medium' : ''" v-else>
            <img style="width: 100%;" @click="clickPage(item)" :src="item.pictureUrl" alt="" />
          </h3>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- <div class="desktop-top"></div> :class="item.link ? 'medium' : ''"-->
    <!-- <div class="lx">
      <div class="home-b">
        <div class="home-b-text">
          <div class="home-left">
            <p>业务咨询</p>
            <p>随时联系我们的咨询专家</p>
            <p>
              <span class="ht" style="position: relative; top: 3px"></span>
              <span>韩经理：13995648183</span>
            </p>
          </div>
          <div class="home-left-1">
            <p>商务邮箱</p>
            <p>我们的商务经理会第一时间联系您</p>
            <p>
              <img
                style="position: relative; top: -2px; right: 5px"
                src="../../../assets/images/Lx/z-1-4.png"
              />
              <span>zuoyi@iningmei.com</span>
            </p>
          </div>
          <div class="home-two">
            <div class="home-content">
              <p></p>
              <p>在线联系我们，获得专业建议</p>
              <p @click="handleOpen">立即咨询</p>
            </div>
            <div class="home-right">
              <p></p>
              <p>提交产品需求，保持沟通</p>
              <p @click="workOrderSubmite">立即提交</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div>
      <div v-for="(item, index) in contentTop">
        <el-image
          style="width: 100%; height: 650px"
          :class="item.clickEvent == 0 ? 'medium' : ''"
          @click="clickPage(item)"
          :src="item.pictureUrl"
          v-if="item.bannerType == 0"
        ></el-image>
        <div style="position: relative">
          <video
            :id="'topMedio' + index"
            class="movechange"
            @click="changeVideoStatus1(index,item)"
            v-if="item.bannerType == 1"
            :src="item.videoUrl"
            :poster="item.videoCoverUrl"
            width="1920"
            :fill="true"
            height="800"
          ></video>
          <i
            @click="changeVideoStatus(index,item)"
            class="start-play"
            v-if="item.bannerType == 1 && item.videoIsPlay"
          />
        </div>
      </div>
    </div>
    <div class="desktop-fore">
      <DesktopCarousel></DesktopCarousel>
    </div>
    <div style="margin-top: 20px">
      <div v-for="(item, index) in contentBottom">
        <el-image
          style="width: 100%; height: 650px"
          :class="item.clickEvent == 0 ? 'medium' : ''"
          @click="clickPage(item)"
          :src="item.pictureUrl"
          v-if="item.bannerType == 0"
        ></el-image>
        <div style="position: relative">
          <video
            :id="'bottomMedio' + index"
            class="movechange"
            @click="changeVideoStatus3(index,item)"
            v-if="item.bannerType == 1"
            :src="item.videoUrl"
            :poster="item.videoCoverUrl"
            width="1920"
            :fill="true"
            height="800"
          ></video>
          <i
            @click="changeVideoStatus2(index,item)"
            class="start-play"
            v-if="item.bannerType == 1 && item.videoIsPlay"
          />
        </div>
      </div>
    </div>
    <div class="desktop-one">
		<div class="text-content">
			<div class="text-head">我们的标准交付与售后保障</div>
			<div class="text-body">
致力于为客户提供完整的产品解决方案，15年行业积累，深耕硬件和主机领域,累计服务超1000万个人及企业用户,我们的专业顾问与您一起讨论性能需求，我们与客户建立关系，以便更好地理解、服务、匹配您的需求。
			</div>
			<div class="text-img">
				<div class="img-box">
					<img  src="./images/1.png" alt="" />
					<div class="img-box-text">三年整机上门服务</div>
				</div>
				<div class="img-box">
					<img  src="./images/2.png" alt="" />
					<div class="img-box-text">七天无理由退货</div>
				</div>
				<div class="img-box">
					<img  src="./images/3.png" alt="" />
					<div class="img-box-text">十五天包换</div>
				</div>
				<div class="img-box">
					<img  src="./images/4.png" alt="" />
					<div class="img-box-text">售后团队两小时内响应</div>
				</div>
				<div class="img-box">
					<img  src="./images/5.png" alt="" />
					<div class="img-box-text">全国顺丰包邮</div>
				</div>
				<div class="img-box">
					<img  src="./images/6.png" alt="" />
					<div class="img-box-text">支持对公转账</div>
				</div>
				<div class="img-box">
					<img  src="./images/7.png" alt="" />
					<div class="img-box-text">可开专票</div>
				</div>
				<div  class="img-box">
					<img  src="./images/8.png" alt="" />
					<div class="img-box-text">可依需定制化产品</div>
				</div>
			</div>
		</div>
	</div>
    <div class="desktop-three"></div>
  </div>
  <el-dialog
    style="border-radius: 20px; width: 521px; height: 646px; position: relative"
    :show-close="false"
    destroy-on-close="true"
    v-model="isVisible"
  >
    <workOrder
      @closeOpen="closeOpen"
      style="position: absolute; left: -63px; top: 30px"
    />
  </el-dialog>
</template>

<script setup>
import DesktopCarousel from "./DesktopCarousel.vue";
import { ref, onMounted } from "vue";
import workOrder from "@/views/workOrder";
import { machineBanner, queryContent } from "@/api/Home/index.js";
import { useRoute } from "vue-router";
import emitter from "@/utils/eventBus";
const handleOpen = () => {
  emitter.emit("onlineClient");
};
const route = useRoute();
const autoPlay = ref(false);
const banner = ref();
const lokk = ref(null);
const dialogVisible = ref(false);
const handleended = () => {
  autoPlay.value = true;
  lokk.value.next();
};
const clickPage = (val) => {
  if (val.clickEvent == 0) {
    if (val.link) {
      if (val.openWay == 1) {
        window.location.href = val.link;
      } else {
        window.open(val.link);
      }
    }
  }
};

const machineCode = ref(route.params.id);
const contentTop = ref([]);
const contentBottom = ref([]);
const changeVideoStatus = (index,data) => {
  var Media = document.getElementById('topMedio'+index);
  data.videoIsPlay = false
  Media.controls = true;
  Media.play();
};
const changeVideoStatus1 = (index,data) => {
  document.getElementById('topMedio'+index).onfullscreenchange = function (e) {
    if (!document.fullscreenElement) {
      document.getElementById('topMedio'+index).scrollIntoView();
    }
  };
  var Media = document.getElementById('topMedio'+index);
  data.videoIsPlay = false
  Media.controls = true;
};
const changeVideoStatus2 = (index,data) => {
  var Media = document.getElementById("bottomMedio"+index);
  data.videoIsPlay = false
  Media.controls = true;
  Media.play();
};
const changeVideoStatus3 = (index,data) => {
  document.getElementById("bottomMedio"+index).onfullscreenchange = function (e) {
    if (!document.fullscreenElement) {
      document.getElementById("bottomMedio"+index).scrollIntoView();
    }
  };
  var Media = document.getElementById("bottomMedio"+index);
  data.videoIsPlay = false
  Media.controls = true;
};
onMounted(() => {
  machineBanner(machineCode.value).then((res) => {
    const data = res.data;
    const dataPc = [];
    data.forEach((item) => {
      if (item.systemType == 0) {
        dataPc.push(item);
      }
    });
    banner.value = dataPc;
  });
  queryContent(machineCode.value).then((res) => {
    const data = res.data;
    const dataPc = [];
    data.forEach((item) => {
      if (item.systemType == 0) {
        dataPc.push(item);
      }
    });
    dataPc.forEach((item) => {
      if (item.contentPosition == 0) {
        contentTop.value.push(item);
        contentTop.value.forEach((select)=>{
          select.videoIsPlay = true
        })
      } else {
        contentBottom.value.push(item);
        contentBottom.value.forEach((select)=>{
          select.videoIsPlay = true
        })
      }
    });
  });
  window.onload = function () {
    window.scrollTo(0, 0);
  };
});
const check = (val) => {
  let Media = document.getElementById("media");
  if (Media) {
    if (val == 0) {
      autoPlay.value = false;
      Media.currentTime = 0;
      Media.play();
    }
  }
};
const isVisible = ref(false);
const workOrderSubmite = () => {
  isVisible.value = true;
};
const closeOpen = () => {
  isVisible.value = false;
};
</script>

<style lang="less" scoped>
/* .movechange:hover {
  background-color: #000000;
  opacity: 0.9;
} */
.start-play {
  cursor: pointer;
  width: 120px;
  height: 120px;
  background: url("./images/play.png") no-repeat;
  display: block;
  position: absolute;
  margin: auto;
  top: 60px;
  bottom: 0;
  left: 10px;
  right: 0;
  &::after {
    content: "";
    width: 0;
    height: 0;
    border: 13px solid transparent;
    position: absolute;
    top: 32%;
    left: 40%;
  }
}

video {
  // object-fit: fill;
  background-color: #111111;
  width: 100%;
}
.container-desktop {
  width: 1920px;
  margin: 0 auto;
  .desktop-top {
    width: 100%;
    height: 800px;
    background-image: url("../../../assets/images/sy/t-12.png");
    background-repeat: no-repeat;
    // margin-bottom: 60px;
  }
  .medium:hover {
    cursor: pointer;
  }
  .lx {
    width: 1920px;
    background-color: #fff;
    padding-top: 40px;
    padding-bottom: 40px;
    .home-b {
      width: 1680px;
      height: 200px;
      background-image: url("../../../assets/images/Lx/z-1-2.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin: 0 auto;
      .home-b-text {
        display: flex;
        padding: 0 0 0 8px;
        justify-content: center;
        .home-left {
          padding: 54px 0 61px 0;
          width: 326px;
          p:nth-child(1) {
            font-size: 20px;
            font-weight: 400;
            color: #111111;
            margin-bottom: 5px;
          }
          P:nth-child(2) {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            margin-bottom: 5px;
          }
          P:nth-child(3) {
            font-size: 24px;
            font-weight: 400;
            color: #ce1200;
            .ht {
              display: inline-block;
              width: 20px;
              height: 24px;
              background-image: url("../../../assets/images/Lx/z-1-1.png");
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              margin-right: 7px;
              position: relative;
              top: 4px;
            }
          }
        }
        .home-left-1 {
          margin-top: 54px;
          width: 369px;
          p:nth-child(1) {
            font-size: 20px;
            font-weight: 400;
            color: #111111;
            margin-bottom: 5px;
          }
          P:nth-child(2) {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
            margin-bottom: 5px;
          }
          P:nth-child(3) {
            font-size: 24px;
            font-weight: 400;
            margin-left: 3px;
            color: #ce1200;
            .ht {
              display: inline-block;
              /* width: 24px;
            height: 24px; */
              /* background-image: url("../../../assets/images/Lx/z-1-4.png"); */
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              margin-right: 7px;
            }
          }
        }
        .home-two {
          display: flex;
          .home-content {
            padding: 45px 48px 45px 60px;
            p:nth-child(1) {
              width: 40px;
              height: 36px;
              background-image: url("../../../assets/images/solve/td.png");
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              margin: auto;
            }
            p:nth-child(2) {
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #111111;
              margin: 14px 0 11px 0;
            }
            p:nth-child(3) {
              width: 120px;
              height: 30px;
              line-height: 30px;
              background: #ce1200;
              border-radius: 15px;
              color: #ffffff;
              margin: 0 auto;
              text-align: center;
              cursor: pointer;
            }
          }
          .home-right {
            padding: 41px 0 39px 0;
            p:nth-child(1) {
              margin-bottom: 11px;
              width: 43px;
              height: 43px;
              background-image: url("../../../assets/images/solve/xz.png");
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
              margin: auto;
            }
            P:nth-child(2) {
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #111111;
              margin: 11px 0 11px 0;
            }
            p:nth-child(3) {
              width: 120px;
              height: 30px;
              line-height: 30px;
              background: #ce1200;
              border-radius: 15px;
              color: #ffffff;
              margin: 0 auto;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .desktop-one {
    width: 1680px;
    height: 579px;
    margin: 60px auto;
	background-color: white;
	overflow: hidden;
	
	.text-content{
		margin-top: 60px;
		display: flex;
		flex-direction: column;
		
		.text-head{
			text-align: center;
			font-weight: bold;
			font-size: 32px;
			font-family: 'MicrosoftYaHei';
			color: #111111;
		}
		.text-body{
			width:1434px;
			padding-top: 39px;
			margin: 0 auto;
			font-size: 24px;
			color: #666666;
		}
		.text-img{
			padding: 40px 0;
			box-sizing: border-box;
			margin: 0 auto;
			height: 382px;
			width: 1200px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			
			.img-box{
				width: 25%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				
				 
				.img-box-text{
					margin-top: 21px;
					font-size: 24px;
					color: #333333;
				}
				
			}
			
			
		}
		.text-img>.img-box:nth-child(1)>img{
			width: 49px;
			height: 61px;
		}
		.text-img>.img-box:nth-child(2)>img{
			width: 63px;
			height: 59px;
		}
		.text-img>.img-box:nth-child(3)>img{
			width: 57px;
			height: 56px;
		}
		.text-img>.img-box:nth-child(4)>img{
			width: 74px;
			height: 57px;
		}
		.text-img>.img-box:nth-child(5)>img{
			width: 60px;
			height: 59px;
		}
		.text-img>.img-box:nth-child(6)>img{
			width: 57px;
			height: 52px;
		}
		.text-img>.img-box:nth-child(7)>img{
			width: 59px;
			height: 53px;
		}
		.text-img>.img-box:nth-child(8)>img{
			width: 53px;
			height: 53px;
		}
	}
  }
  .desktop-three {
    width: 1680px;
    height: 762px;
    margin: 0 auto;
    background-image: url("../../../assets/images/sy/z-18.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
